import { socket, sendData, onData } from '../Hooks/Socket';
import fixWebmDuration from 'webm-duration-fix';
import logger from './Logger';
const FILE_NAME = 'recordinger.JS';

class Recorder {
  constructor() {
    this.taggedUserList = [];
    this.idleTrackingRequired = false;
    this.idleTracking = null;
    this.idleTrackingAuthLevel = null;
    this.startIdleTracking = null;
    this.idleGrant = false;
    this.idleController = null;
    this.idleDetector = null;
    this.idleTimer = null;
    this.recording_position = 0;
    this.currentVolumeLevel = 0;
    this.isStreaming = false;
    this.clipUID = null;
    this.isClip = false;
    this.clipName = '';
    this.streamTimeout = '';
    this.startClipTime = null;
    this.startStreamTime = new Date().getTime();
    this.recorderInterval = [];
    this.streamIntervalMS = 30000;
    this.stream = null;
    this.streamPosition = 0;
    this.streamId = null;
    this.switchCounter = 0;
    this.streamNumMonitors = parseInt(localStorage.getItem('monitors'));
    this.clipNumMonitors = parseInt(localStorage.getItem('monitors'));
    this.mediaClips = [];
    this.mediaStreams = [];
    this.streamTracks = [];
    this.clipTracks = [];
    this.streamRecorders = [];
    this.streamRecordersChunks = [];
    this.clipRecorders = [];
    this.clipRecordersChunks = [];
    this.currentDay = null;
    this.clipError = () => {};
    this.streamError = () => {};
    this.videoStartTime = null;
    this.segmentDuration = 0;
    this.isIdle = false;
    this.idleTime = 0;
    this.updateRecordingDisplay = () => {};
    this.startModal = () => {};
    this.clearModal = () => {};
    this.isTimeoutModalVisible = false;
    this.frameDurations = '';

    onData('startStream', this.onStartStream);
    onData('startClip', this.onStartClip);
    onData('contactEvent', this.contactEvent);

    // Set lastRefresh to now when the page loads
    localStorage.setItem('lastRefresh', Date.now());

    // Set up an interval to check every hour (3600000 ms)
    this.refreshInterval = setInterval(
      () => this.checkRefreshNeeded(),
      3600000
    );
  }

  checkRefreshNeeded() {
    const lastRefresh = parseInt(localStorage.getItem('lastRefresh'), 10);
    const now = Date.now();
    const eightHoursInMs = 8 * 60 * 60 * 1000;

    // Check if more than eight hours have passed since the last refresh
    if (!this.isStreaming && now - lastRefresh > eightHoursInMs) {
      localStorage.setItem('lastRefresh', now);
      logger(
        'info',
        FILE_NAME,
        10,
        'Refreshing page to load the latest version.',
        this
      );
      window.location.reload();
    }
  }

  handleBrowserUnload = (event) => {
    if (this.isStreaming) {
      logger(
        'info',
        FILE_NAME,
        20,
        'Browser closed or refreshed while streaming',
        this
      );
    }
  };

  onStartClip = (data) => {
    console.log(data);
    this.clipUID = data.UID;
    this.clipId = data.id;
  };

  onStartStream = (data) => {
    logger('info', FILE_NAME, 18, 'onStartStream Signal Recieved', this);
    this.streamId = data.streamId;
  };

  setClipNumMonitors = (num) => {
    this.clipNumMonitors = num;
  };

  setStreamNumMonitors = (num) => {
    this.streamNumMonitors = num;
  };

  sendStartClip = () => {
    let data = {
      genUserId: localStorage.getItem('genUserId'),
    };
    logger('info', FILE_NAME, 19, 'Send Start Clip', this);
    sendData('startClip', data);
  };

  startClipping = (clipName) => {
    //This function is defined in the navbar.js to update the recording icons on the navbar
    this.updateRecordingDisplay(true, 'clip');

    this.clipName = clipName;
    this.isClip = true;
    this.currentDay =
      ('0' + (new Date().getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + new Date().getDate()).slice(-2) +
      '-' +
      new Date().getFullYear();

    this.getClipTracks(this.clipNumMonitors);
  };

  getClipTracks = async (numMonitors) => {
    this.constraints = {
      video: {
        width: parseInt(localStorage.getItem('width')),
        height: parseInt(localStorage.getItem('height')),
        frameRate: parseInt(localStorage.getItem('frameRate')),
        facingMode: 'user',
      },
      audio: {
        sampleSize: parseInt(localStorage.getItem('sampleSize')),
        sampleRate: parseInt(localStorage.getItem('sampleRate')),
      },
    };

    this.clipTracks = [];
    this.mediaClips = [];
    for (let i = 0; i < numMonitors; i++) {
      let desktopClip;
      let voiceClip;

      try {
        desktopClip = await navigator.mediaDevices.getDisplayMedia(
          this.constraints
        );
      } catch (err) {
        if (err.message != 'Permission denied') {
          this.stopClipTracks();
          this.getClipTracks(numMonitors);
        } else {
          this.isClip = false;
          this.clipName = '';
          this.stopClipTracks();
          this.clipError(
            'You must give us permission to share your screen(s) before you can begin clipping!',
            true
          );
        }

        sendData('stopClipping');
        return;
      }

      try {
        voiceClip = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: {
            sampleSize: parseInt(localStorage.getItem('sampleSize')),
            sampleRate: parseInt(localStorage.getItem('sampleRate')),
          },
        });
      } catch (err) {
        console.log(err);
        this.clipError(
          'We encountered an error while trying to access your microphone. Your clip will continue without your microphone input.',
          false
        );
      }

      this.clipTracks.push(desktopClip);
      this.clipTracks.push(voiceClip);
      let tracks = [
        ...desktopClip.getVideoTracks(),
        ...this.mergeTracks(desktopClip, voiceClip),
      ];
      this.mediaClips.push(new MediaStream(tracks));
    }

    this.clipAndSend(numMonitors);
  };

  clipAndSend = (numMonitors) => {
    this.options = {
      audioBitsPerSecond: parseInt(localStorage.getItem('audioBitsPerSecond')),
      videoBitsPerSecond: parseInt(localStorage.getItem('videoBitsPerSecond')),
      mimeType: 'video/webm; codecs="h264"',
    };

    this.clipRecorders = [];
    this.clipRecordersChunks = [];
    for (let i = 0; i < numMonitors; i++) {
      this.clipRecorders.push(
        new MediaRecorder(this.mediaClips[i], this.options)
      );
      this.clipRecordersChunks[i] = [];
    }

    this.clipRecorders.forEach((clipRecorder, index) => {
      clipRecorder.ondataavailable = (e) =>
        this.clipRecordersChunks[index].push(e.data);
      clipRecorder.onstop = async (e) => {
        const blob = await fixWebmDuration(
          new Blob([...this.clipRecordersChunks[index]], {
            type: 'video/webm;codecs=h264',
          })
        );
        blob.arrayBuffer().then((buffer) => {
          let monitor = index + 1;
          let genUserId = localStorage.getItem('genUserId');

          console.log(Recorder.taggedUserList);
          let params = {
            method: 'POST',
            credentials: `include`,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              genUserId: genUserId,
              type: 'putObject',
              UID: this.clipUID,
              name: this.clipName,
              startTime: this.startClipTime,
              created: new Date(),
              monitor: monitor,
              numMonitors: this.clipNumMonitors,
              currentDay: this.currentDay,
              clip: 1,
            }),
          };

          let clipId = null;
          fetch(`${window.apiSite}/generateSignedUrl`, params)
            .then((res) => {
              if (!res.ok) {
                throw new Error(
                  `Error in HTTP Request: ${res.status} (${res.statusText})`
                );
              } else {
                return res.json();
              }
            })
            .then((data) => {
              clipId = data.clipId;
              return fetch(data.signedUrl, {
                method: 'PUT',
                body: buffer,
                headers: { 'Content-Type': 'video/mp4' },
              });
            })
            .then((res) => {
              if (!res.ok) {
                throw new Error(
                  `Error in HTTP Request: ${res.status} (${res.statusText})`
                );
              }

              if (monitor === 1) {
                //If clip upload successful, post clip mentions
                let regex1 = /@\[[^\]]*]\{\d*\}/g;
                let arrMatches = [];
                if (this.comment) {
                  this.comment = String(this.comment);
                  arrMatches = this.comment.match(regex1);

                  if (arrMatches) {
                    for (let i = 0; i < arrMatches.length; i++) {
                      let replacementStr =
                        arrMatches[i].match(/@\[[^\]]*]/g)[0];
                      replacementStr = replacementStr.replace('[', '');
                      replacementStr = replacementStr.replace(']', '');
                      console.log(replacementStr);
                      this.comment = this.comment.replace(
                        arrMatches[i],
                        replacementStr
                      );
                    }
                  }
                }

                let isClipComment = true;
                let timeOffset = 0;

                let taggedArray = null;
                if (this.mentions) {
                  taggedArray = [...this.mentions];
                  taggedArray[0] = `(${taggedArray[0]}`;
                  taggedArray[taggedArray.length - 1] = `${
                    taggedArray[taggedArray.length - 1]
                  })`;
                  taggedArray = taggedArray.toString();
                }

                let commentData = {
                  taggedList: taggedArray,
                  idArray: this.mentions,
                  id: clipId,
                  timestamp: new Date(),
                  streamDate: null,
                  isClip: isClipComment,
                  clipUID: this.clipUID,
                  frame: null,
                  genUserId: localStorage.getItem('genUserId'),
                  comment: this.comment,
                  timeInFrame: timeOffset,
                };
                sendData('setComment', commentData);

                this.mentions = null;
                this.comment = null;

                this.promptCopyClip(clipId, this.clipUID);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        });
      };
    });

    for (let i = 0; i < numMonitors; i++) {
      this.startClipTime = new Date().getTime();
      this.sendStartClip();
      this.clipRecorders[i].start();
    }
  };

  stopClipping = () => {
    try {
      //This function is defined in the navbar.js to update the recording icons on the navbar
      this.updateRecordingDisplay(false, 'clip');

      this.clipRecorders.forEach((clipRecorder) => {
        clipRecorder.stop();
      });

      this.isClip = false;
      this.stopClipTracks();
    } catch (err) {
      console.error(err);
    }
  };

  stopClipTracks = () => {
    try {
      for (let i = 0; i < this.clipTracks.length; i++) {
        let tracks = this.clipTracks[i].getTracks();

        tracks.forEach((track) => track.stop());
      }

      this.clipTracks = [];
    } catch (err) {
      console.error(err);
    }
  };

  startStreaming = () => {
    logger('info', FILE_NAME, 21, 'startStreaming function called', this);
    this.segmentDuration = 0;
    this.frameDurations = '';
    if (this.idleTrackingRequired) {
      if (!this.idleTracking) {
        logger('info', FILE_NAME, 22, 'Missing idle tracking permission', this);
        alert('You must have activity tracking enabled to start streaming!');
        this.streamError('Missing idle tracking permission', true);
        return;
      } else {
        let idleData = {
          genUserId: localStorage.getItem('genUserId'),
          userId: localStorage.getItem('userId'),
          state: 'active',
          timeOffset: 0,
          frame: this.streamPosition,
        };
        sendData('insertUserIdle', idleData);
        this.currentDay =
          ('0' + (new Date().getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + new Date().getDate()).slice(-2) +
          '-' +
          new Date().getFullYear();

        //This function is defined in the navbar.js to update the recording icons on the navbar
        this.updateRecordingDisplay(true, 'stream');

        this.isStreaming = true;

        //this function is defined in the idle.js function in components/idle.js
        // this.startIdleTracking();
        logger(
          'info',
          FILE_NAME,
          23,
          'Stream Started - Tracking Required',
          this
        );
        this.getStreamTracks(this.streamNumMonitors);
      }
    } else {
      this.currentDay =
        ('0' + (new Date().getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + new Date().getDate()).slice(-2) +
        '-' +
        new Date().getFullYear();

      //This function is defined in the navbar.js to update the recording icons on the navbar
      this.updateRecordingDisplay(true, 'stream');

      this.isStreaming = true;
      logger(
        'info',
        FILE_NAME,
        24,
        'Stream Started - Tracking Not Required',
        this
      );
      this.getStreamTracks(this.streamNumMonitors);
    }

    if (this.isStreaming) {
      window.addEventListener('beforeunload', this.handleBrowserUnload);
    }
  };

  sendStartStream = (userGenerated) => {
    this.switchCounter = 0;
    this.startStreamTime = new Date().getTime();

    let streamData = {
      genUserId: localStorage.getItem('genUserId'),
      userId: localStorage.getItem('userId'),
      date: this.currentDay,
      position: this.streamPosition,
      startTime: this.startStreamTime,
      userGenerated: userGenerated,
    };

    sendData('startStream', streamData);
  };

  getStreamTracks = async (numMonitors) => {
    const recordAudio =
      localStorage.getItem('recordAudio') === 'true' ? true : false;

    if (recordAudio) {
      this.constraints = {
        video: {
          width: parseInt(localStorage.getItem('width')),
          height: parseInt(localStorage.getItem('height')),
          frameRate: parseInt(localStorage.getItem('frameRate')),
          facingMode: 'user',
        },
        audio: {
          sampleSize: parseInt(localStorage.getItem('sampleSize')),
          sampleRate: parseInt(localStorage.getItem('sampleRate')),
        },
      };
    } else {
      this.constraints = {
        video: {
          width: parseInt(localStorage.getItem('width')),
          height: parseInt(localStorage.getItem('height')),
          frameRate: parseInt(localStorage.getItem('frameRate')),
          facingMode: 'user',
        },
        audio: false,
      };
    }

    this.streamTracks = [];
    this.mediaStreams = [];
    for (let i = 0; i < numMonitors; i++) {
      console.log(numMonitors);
      let desktopStream;
      let voiceStream;

      try {
        desktopStream = await navigator.mediaDevices.getDisplayMedia(
          this.constraints
        );
      } catch (err) {
        //If permission was not denied, retry getting stream tracks
        if (err.message !== 'Permission denied') {
          logger('error', FILE_NAME, 11, err, this);
          this.stopStreamTracks();
          this.getStreamTracks(numMonitors);
        } else {
          logger(
            'error',
            FILE_NAME,
            12,
            'Screen Share Permission Denied',
            this
          );
          this.streamError(
            'You must give us permission to share your screen(s) before you can begin streaming!',
            true
          );
          this.isStreaming = false;
          this.stopStreamTracks();
        }

        sendData('stopBroadcast');
        return;
      }

      try {
        if (recordAudio) {
          voiceStream = await navigator.mediaDevices
            .getUserMedia({
              video: false,
              audio: {
                sampleSize: parseInt(localStorage.getItem('sampleSize')),
                sampleRate: parseInt(localStorage.getItem('sampleRate')),
              },
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } catch (err) {
        logger('error', FILE_NAME, 13, err, this);
        this.streamError(
          'There was an issue getting your microphone. Your stream will continue without your microphone input.',
          false
        );
      }

      this.streamTracks.push(desktopStream);
      this.streamTracks.push(voiceStream);
      let tracks = [
        ...desktopStream.getVideoTracks(),
        ...this.mergeTracks(desktopStream, voiceStream),
      ];
      this.mediaStreams.push(new MediaStream(tracks));
      console.log(this.mediaStreams[i].getAudioTracks()[0]);
    }

    this.sendStartStream(1);
    this.streamAndSend(numMonitors, localStorage.getItem('userId'));
  };

  onContactEvent = ({ event, userId }) => {
    switch (event) {
      case 'CONNECTING':
        this.enableAudioTracks();
        break;
      case 'ENDED':
        this.disableAudioTracks();
        break;
      default:
        logger('error', FILE_NAME, 14, 'No Contact Event', this);
        break;
    }
  };

  enableAudioTracks = () => {
    for (let i = 0; i < this.mediaStreams.length; i++) {
      this.mediaStreams[i].getAudioTracks()[0].enabled = true;
    }
  };

  disableAudioTracks = () => {
    for (let i = 0; i < this.mediaStreams.length; i++) {
      this.mediaStreams[i].getAudioTracks()[0].enabled = false;
    }
  };

  mergeTracks = (desktopStream, voiceStream) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };

  handleStreamStop = async (monitor, position, userId) => {
    //New Webm Duration fix
    console.log(monitor);
    console.log(position);
    console.log(userId);
    const blob = await fixWebmDuration(
      new Blob([...this.streamRecordersChunks[monitor - 1]], {
        type: 'video/webm;codecs=h264',
      })
    );

    blob.arrayBuffer().then((buffer) => {
      socket.emit('addFilePart', {
        userId: userId,
        streamPosition: position,
        monitor: monitor,
        genUserId: localStorage.getItem('genUserId'),
        segmentDuration: this.segmentDuration,
        frameDurations: this.frameDurations,
        currentDay: this.currentDay,
        streamId: this.streamId,
      });

      if (monitor == this.streamNumMonitors) {
        this.switchCounter += 1;
      }

      if (this.switchCounter >= 30) {
        if (monitor == this.streamNumMonitors) {
          if (this.segmentDuration > 950000 || this.segmentDuration < 850000) {
            sendData('errorReport', {
              error: 'Segment Duration is out of range',
              type: 'int_out_of_range',
              file: 'Recorder.js',
              expectedData: '850000 - 950000',
              actualData: this.segmentDuration,
            });
          }
          this.frameDurations = '';
          this.segmentDuration = 0;
          this.sendStartStream(0);
        }
      }

      let genUserId = localStorage.getItem('genUserId');

      let params = {
        method: 'POST',
        credentials: `include`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          genUserId: genUserId,
          type: 'putObject',
          currentDay: this.currentDay,
          monitor: monitor,
          streamPosition: position,
          clip: 0,
        }),
      };

      fetch(`${window.apiSite}/generateSignedUrl`, params)
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              `Error in HTTP Request: ${res.status} (${res.statusText})`
            );
          } else {
            return res.json();
          }
        })
        .then((data) => {
          return fetch(data.signedUrl, {
            method: 'PUT',
            body: buffer,
            headers: { 'Content-Type': 'video/mp4' },
          });
        })
        .then((res) => {
          //Check successful upload
          if (!res.ok) {
            throw new Error(
              `Error in HTTP Request: ${res.status} (${res.statusText})`
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });

      if (monitor == this.streamNumMonitors) {
        this.streamPosition++;
      }
    });
  };

  streamAndSend = (numMonitors, userId) => {
    this.options = {
      audioBitsPerSecond: parseInt(localStorage.getItem('audioBitsPerSecond')),
      videoBitsPerSecond: parseInt(localStorage.getItem('videoBitsPerSecond')),
      mimeType: 'video/webm; codecs="h264"',
    };

    this.streamRecorders = [];
    this.streamRecordersChunks = [];
    for (let i = 0; i < numMonitors; i++) {
      this.streamRecorders[i] = new MediaRecorder(
        this.mediaStreams[i],
        this.options
      );
      this.streamRecordersChunks[i] = [];
    }

    this.streamRecorders.forEach((streamRecorder, index) => {
      let position = this.streamPosition;
      if (position == 0 && index == this.streamRecorders.length - 1) {
        this.streamPosition++;
      }
      streamRecorder.ondataavailable = (e) =>
        this.streamRecordersChunks[index].push(e.data);
      streamRecorder.onstop = (e) => {
        this.handleStreamStop(index + 1, position, userId);
      };
    });

    this.streamTimeout = this.setExactTimeout(
      () => {
        try {
          this.streamRecorders.forEach((streamRecorder) => {
            streamRecorder.stop();
          });

          this.streamAndSend(this.streamNumMonitors, userId);
        } catch (err) {
          console.error(err);
        }
      },
      this.streamIntervalMS,
      15
    );

    if (this.isStreaming) {
      for (let i = 0; i < numMonitors; i++) {
        this.streamRecorders[i].start();
      }
    }
  };

  stopStreaming = () => {
    try {
      logger('info', FILE_NAME, 25, 'Stream Stopped By User', this);
      clearTimeout(this.streamTimeout);
      sendData('streamReminderReport', { event: 3 });
      sendData('stopBroadcast');
      this.updateRecordingDisplay(false, 'stream');

      this.isStreaming = false;
      sendData('updateExtensionState', { isStreaming: false });

      this.segmentDuration += new Date().getTime() - this.videoStartTime;

      this.streamRecorders.forEach((streamRecorder) => {
        streamRecorder.stop();
      });

      this.streamRecorders = [];

      //Must be called so the last file is not corrupt
      this.streamAndSend(
        this.streamNumMonitors,
        localStorage.getItem('userId')
      );
      window.removeEventListener('beforeunload', this.handleBrowserUnload);

      this.stopStreamTracks();
    } catch (err) {
      //console.error(err);
    }
  };

  stopStreamTracks = () => {
    try {
      clearTimeout(this.streamTimeout);
      for (let i = 0; i < this.streamTracks.length; i++) {
        let tracks = this.streamTracks[i].getTracks();

        tracks.forEach((track) => track.stop());
      }

      this.streamTracks = [];
    } catch (e) {
      console.error(e);
    }
  };

  setExactTimeout = (callback, expectedDuration, resolution) => {
    const start = new Date().getTime();
    this.videoStartTime = start;

    const timeout = setInterval(() => {
      let currentDateTime = new Date().getTime();
      let exactDuration = currentDateTime - start;
      if (this.isIdle) {
        let idleDiff = currentDateTime - this.idleTime;
        if (idleDiff > 3600000 && !this.isTimeoutModalVisible) {
          //3600000
          this.isTimeoutModalVisible = true;
          this.startModal();

          //trigger Modal Timer
        }
        if (idleDiff > 3900000) {
          //3900000
          this.stopStreaming();

          if (this.isClip) {
            this.stopClipping();
          }

          this.clearModal();
        }
      }

      if (exactDuration > expectedDuration) {
        this.segmentDuration += exactDuration;

        if (this.frameDurations.length != 0) {
          this.frameDurations = `${this.frameDurations},${this.segmentDuration}`;
        } else {
          this.frameDurations = `${this.segmentDuration}`;
        }
        console.log(this.frameDurations);

        callback();
        clearInterval(timeout);
      }
    }, resolution);

    return timeout;
  };

  clearExactTimeout = (timeout) => {
    clearInterval(timeout);
  };
}

export default new Recorder();
